<template>
  <b-modal
    modal-class="component-modal-treatment-plan treatment-plan-modal"
    id="treatment-plan"
    ref="TreatmentPlan"
    size="fluid"
    header-class="py-2"
    title="แผนการรักษา"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    ok-title="บันทึก"
    ok-only
    @hide="onClose"
    @hidden="defaultValue"
  >
    <b-row>
      <b-col cols="12" sm="6" md="4" xl="3">
        <b-card
          ><TeethDiagram
            id="tooth-diagram-treatment-treatmentPlan"
            v-model="teethSelected"
            @hoveringTooth="handleHoveringTooth"
            @input="onShow"
            :disabled="!canCreate && !canEdit"
            :highlightItems="highlightItems"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-table-simple hover responsive="" :bordered="false" small show-empty>
          <b-thead>
            <b-tr>
              <b-th></b-th>
              <b-th></b-th>
              <b-th>ซี่ฟัน</b-th>
              <b-th>ด้าน</b-th>
              <b-th>วินิจฉัย</b-th>
              <b-th>การรักษา</b-th>
              <b-th class="text-right">ราคา</b-th>
              <b-th class="text-right">สถานะการรักษา</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <draggable
            :list="treatmentPlan"
            tag="tbody"
            handle=".drag-handle"
            @end="onDragEnd"
            :group="{ name: 'treatmentItems', pull: false, put: false }"
            :key="'draggable-' + treatmentPlan.length"
            :animation="200"
          >
            <b-tr
              v-for="(item, index) in treatmentPlan"
              :key="item.id || `temp-${index}`"
            >
              <b-td>
                <i class="fa fa-bars drag-handle"></i>
              </b-td>
              <b-td>{{ index + 1 }}.</b-td>
              <b-td>{{ getToothBy(item.toothNo, "id").name }}</b-td>
              <b-td>{{ formatToothSurfaces(item.toothSurfaces) }}</b-td>
              <b-td>{{ formatDiagnosis(item.diagnosis) }}</b-td>
              <b-td
                >{{ formatTreatment(item.treatment) }}
                <b-card v-if="item && item._showDetails" body-class="p-1">
                  {{ item.detail }}
                </b-card></b-td
              >
              <b-td class="text-right">{{ item.price }}</b-td>
              <b-td class="text-right">{{ item.status }}</b-td>
              <b-td>
                <span>
                  <b-button
                    v-if="item.detail"
                    size="xs"
                    @click="toggleDetails(item)"
                    class="mr-2 d-inline"
                  >
                    <i class="fa fa-info"></i>
                  </b-button>
                  <b-button
                    v-if="canEdit || canCreate"
                    size="xs"
                    @click="deleteRow(index)"
                    class="mr-2 show-when-hovered d-inline"
                    variant="danger"
                  >
                    <i class="fa fa-trash"></i>
                  </b-button>
                </span>
              </b-td>
            </b-tr>
          </draggable>

          <template #empty>
            <h6 class="text-center text-warning">กรุณาเพิ่มแผนการรักษา</h6>
          </template>
          <tfoot>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td class="font-weight-bold text-right">รวม</b-td>
            <b-td class="text-right">{{ totalPrice }}</b-td>
            <b-td></b-td>
            <b-td></b-td>
          </tfoot>
        </b-table-simple>
      </b-col>
    </b-row>
    <div class=""></div>

    <b-popover
      custom-class="treatment-plan-popover"
      boundary="window"
      target="tooth-diagram-treatment-treatmentPlan"
      placement="right"
      triggers="manual"
      :show="popoverShow"
      ref="treatmentPlanPopover"
    >
      <template #title>
        <b-button @click="onClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        เพิ่มแผนการรักษา
      </template>
      <div @keydown.enter="onAdd">
        <b-form-input
          class="mb-2"
          :value="teethValue"
          disabled
          size="sm"
        ></b-form-input>
        <div class="text-center">
          <ToothSurfaces
            style="width: 150px"
            @getToothSurfacesPopover="getToothSurfacesPopover"
          />
        </div>
        <b-form-group
          v-if="branchLinkedWithNhso"
          label="กรองรายการ:"
          label-cols="4"
          label-align="right"
          class="my-2"
        >
          <b-form-radio-group
            class="col p-0"
            v-model="selectedListType"
            :options="listOptions"
            buttons
            button-variant="outline-info"
            size="sm"
            name="radio-btn-outline"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group class="my-2">
          <b-input-group>
            <multiselect
              class="col p-0 expand-dropdown"
              :class="{
                'is-invalid': $v.diagnosis.$error,
                'multiselect-black-border': !$v.diagnosis.$error,
              }"
              v-model="$v.diagnosis.$model"
              :options="currentDiagnosisList"
              :multiple="false"
              group-values="libs"
              group-label="category"
              :group-select="false"
              placeholder="พิมพ์ค้นหาการวินิจฉัย"
              track-by="nameEn"
              :custom-label="customDxLabel"
              selectLabel
              selectedLabel
              deselectLabel
            >
              <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
              <template #option="props">
                <span v-if="props.option.$isLabel">
                  {{ props.option.$groupLabel }}
                </span>
                <span v-else>
                  <b-button
                    class="mr-1 small"
                    size="xs"
                    variant="outline-info"
                    :pressed="props.option.favorite"
                    @click.stop="favDxToggle(props.option)"
                  >
                    <i class="far fa-star"></i>
                  </b-button>
                  {{ customDxLabel(props.option) }}
                </span>
              </template>
            </multiselect>
            <b-input-group-append>
              <b-button variant="info" @click="openTreatDiagModal" size="sm"
                >Dx. list</b-button
              >
            </b-input-group-append>
          </b-input-group>
          <template v-slot:description>
            <div v-if="$v.diagnosis.$error" class="error">
              *กรุณาลงข้อมูลกาวินิจฉัย
            </div>
          </template>
        </b-form-group>
        <b-form-group class="mb-2">
          <b-input-group>
            <multiselect
              class="col p-0 expand-dropdown"
              :class="{
                'is-invalid': $v.treatment.$error,
                'multiselect-black-border': !$v.treatment.$error,
              }"
              v-model="$v.treatment.$model"
              :options="currentTreatmentList"
              :multiple="false"
              group-values="libs"
              group-label="category"
              :group-select="false"
              placeholder="พิมพ์ค้นหาการรักษา"
              :custom-label="customTxDrugsLabel"
              track-by="nameTh"
              selectLabel
              selectedLabel
              deselectLabel
              @select="focusPriceInput"
            >
              <template #option="props">
                <span v-if="props.option.$isLabel">
                  {{ props.option.$groupLabel }}
                </span>
                <span v-else>
                  <b-button
                    class="mr-1 small"
                    size="xs"
                    variant="outline-info"
                    :pressed="props.option.favorite"
                    @click.stop="favTxToggle(props.option)"
                  >
                    <i class="far fa-star"></i>
                  </b-button>
                  {{ customTxDrugsLabel(props.option) }}
                </span>
              </template>
              <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
            </multiselect>
            <b-input-group-append>
              <b-button variant="info" @click="openTreatModal" size="sm"
                >Tx. list</b-button
              >
            </b-input-group-append>
          </b-input-group>
          <template v-slot:description>
            <div v-if="$v.treatment.$error" class="error">
              *กรุณาลงข้อมูลการรักษา
            </div>
          </template>
        </b-form-group>
        <b-form-group>
          <b-input-group append="บาท/ซี่" size="sm">
            <b-form-input
              ref="treatmentPriceInputPopover"
              type="number"
              placeholder="฿"
              v-model="price"
              number
              lazy-formatter
            ></b-form-input> </b-input-group
        ></b-form-group>
        <b-form-row class="mb-2 limit-height-col-73">
          <div class="favorite-text-area-container col pr-1">
            <b-form-textarea
              v-model="detail"
              placeholder="รายละเอียดเพิ่มเติม"
              rows="3"
              size="sm"
              no-resize
              lazy
            ></b-form-textarea>
            <!-- <FavNoteButton
            storageName="favTxNote"
            @listClicked="handleSelectedFavNote"
          /> -->
          </div>
        </b-form-row>

        <div class="text-right">
          <b-button class="mr-2" @click="onClose" size="sm" variant="danger"
            >ยกเลิก</b-button
          >
          <b-button @click="onAdd" size="sm" variant="primary">เพิ่ม</b-button>
        </div>
      </div>
    </b-popover>

    <div class="d-none">
      <!-- header -->
      <div ref="header">
        <b-row>
          <b-col cols="2" class="text-center">
            <b-img
              class
              fluid
              :src="logoUrl"
              :alt="clinic.name"
              style="height: 95px"
            />
          </b-col>
          <b-col>
            <h4 class="mb-1">{{ clinic.name }}</h4>
            <p v-if="branchInfo && branchInfo.address" class="mb-1 my-justify">
              {{ branchInfo.address }}
            </p>
            <p class="mb-1">
              <span v-if="branchInfo && branchInfo.tel">
                โทร: {{ branchInfo.tel }}
              </span>
              <span v-if="branchInfo && branchInfo.line" class="ml-3">
                Line ID: {{ branchInfo.line }}
              </span>
            </p>
            <p v-if="branchInfo && branchInfo.website" class="mb-1">
              {{ branchInfo.website }}
            </p>
          </b-col>
        </b-row>

        <hr />
      </div>
      <!-- body -->
      <div ref="body">
        <b-row class="mb-1">
          <b-col cols="2" class>วันที่:</b-col>
          <b-col>{{ today }}</b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="2" class>ชื่อ:</b-col>
          <b-col cols>{{ ptFullname }}</b-col>
        </b-row>
        <b-row v-if="ptAddress" class="mb-3">
          <b-col cols="2" class>ที่อยู่:</b-col>
          <b-col cols>{{ ptAddress }}</b-col>
        </b-row>
        <b-row id="tx-plan-doctor-name" class="mb-3">
          <b-col cols="2" class>แพทย์:</b-col>
          <b-col>
            <p>ทพ./ทพญ. {{ doctor }}</p>
          </b-col>
        </b-row>
        <h6>
          <strong>แผนการรักษา และค่าใข้จ่าย</strong>
        </h6>
        <b-table
          ref="treatmentPlanTable"
          :bordered="false"
          :items="printingPlan"
          :fields="printingField"
          small
          show-empty
        >
          <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
          <template #cell(status)> </template>
          <template #cell(buttons)> </template>

          <template #row-details="row">
            <b-card body-class="py-1">
              {{ row.item.detail }}
            </b-card>
          </template>
          <template #bottom-row>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="font-weight-bold text-right">รวม</td>
            <td class="text-right">{{ totalPrice }}</td>
          </template>
        </b-table>
      </div>

      <!-- foooter -->
      <div ref="footer">
        <b-row class style="margin-top: 6rem">
          <b-col class="text-center">
            <span class="border-top px-4 py-3">{{ ptFullname }}</span>
          </b-col>
          <b-col class="text-center">
            <span class="border-top px-4 py-3">ทพ./ทพญ.{{ doctor }}</span>
          </b-col>
        </b-row>
      </div>
    </div>
    <template #modal-footer>
      <b-row class="w-100">
        <b-input-group class="col-12 col-md-6 px-0">
          <b-form-select
            v-model="$v.doctor.$model"
            :options="getDoctorList"
            text-field="fullName"
            value-field="fullName"
            :class="{
              'is-invalid': $v.doctor.$error,
            }"
          >
            <!-- This slot appears above the options from 'options' prop -->
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- กรุณาเลือกแพทย์ --</b-form-select-option
              >
            </template>
          </b-form-select>
          <template #append>
            <b-button variant="info" class="" @click="generatePdf">
              พิมพ์แผนการรักษา
            </b-button>
          </template>
        </b-input-group>
        <b-col class="px-0">
          <b-button
            v-if="canCreate"
            variant="primary"
            class="float-right"
            @click="handleCreateTreatmentPlan"
          >
            บันทึกแผน
          </b-button>
          <b-button
            v-if="canEdit"
            variant="warning"
            class="float-right"
            :disabled="!hasPlanChanged"
            @click="handleCreateTreatmentPlan"
          >
            แก้ไขแผน
          </b-button>
        </b-col>
      </b-row>
    </template>
    <TreatDiagListModal
      ref="txDxPlanMoal"
      @selectedDiagItem="diagnosis = $event"
      @hidden="keepPopoverShown"
    ></TreatDiagListModal>
    <TreatListModal
      ref="treatListModal"
      @selectedTreatment="
        treatment = $event;
        price = $event.price;
      "
      @hidden="keepPopoverShown"
    ></TreatListModal>
    <PdfExport ref="pdfExport" />
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TeethDiagram from "@/components/popover/TeethDiagram";
import ToothSurfaces from "@/components/popover/ToothSurfaces.vue";
import TreatDiagListModal from "@/components/modal/TreatDiagList";
import TreatListModal from "@/components/modal/TreatList";
import PdfExport from "@/components/PdfExport";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import Draggable from "vuedraggable";

import { required, requiredIf } from "vuelidate/lib/validators";

import moment from "moment";

import { eventBus } from "../../main";

export default {
  name: "TreatmentPlan",
  components: {
    // PatientProfile,
    TeethDiagram,
    ToothSurfaces,
    TreatDiagListModal,
    TreatListModal,
    Loading,
    Dialog,
    PdfExport,
    Draggable,
  },
  props: {},

  data() {
    return {
      teethSelected: [],
      toothSurfaces: [],
      diagnosis: null,
      treatment: null,
      price: 0,
      detail: "",

      hoveringTooth: {},
      popoverShow: false,

      planList: [],
      planSelected: {},
      treatmentPlan: [],
      tableFeilds: [
        {
          key: "index",
          label: "",
          printing: true,
        },
        {
          key: "toothNo",
          label: "ซี่ฟัน",
          formatter: value => {
            return this.getToothBy(value, "id").name;
          },
          printing: true,
        },
        {
          key: "toothSurfaces",
          label: "ด้าน",
          formatter: value => {
            let surfaces = [];
            value?.forEach(i => {
              surfaces.push(this.getToothSurfaceBy(i, "id").name);
            });
            return surfaces.join();
          },
          printing: true,
        },
        {
          key: "diagnosis",
          label: "วินิจฉัย",
          formatter: value => {
            let obj = this.getTreatDiagBy(value, "id");
            if (obj?.code) return `[${obj.code}]-${obj.nameEn}`;
            return obj.nameEn;
          },
          printing: true,
        },
        {
          key: "treatment",
          label: "การรักษา",
          formatter: value => {
            let obj = this.getTreatBy(value, "id");
            if (obj?.code) return `[${obj.code}]-${obj.nameTh}`;
            return obj.nameTh;
          },
          printing: true,
        },
        {
          key: "price",
          label: "ราคา",
          class: "text-right",
          printing: true,
        },

        {
          key: "status",
          label: "สถานะการรักษา",
          class: "text-right",
        },
        {
          key: "buttons",
          label: "fda",
        },
      ],
      clinic: {},
      patient: {},
      patientId: null,
      doctor: null,
      isPdfCreating: false,
      originalTreatmentPlan: [],
      printingPlan: [],
      selectedListType: localStorage.getItem("selectedListType") || "Plan",
      listOptions: [
        { text: "ทั้งหมด", value: "Plan" },
        { text: "สปสช", value: "NHSO" },
        { text: "คลินิก", value: "General" },
      ],

      isLoading: false,
    };
  },
  validations: {
    treatment: { required },
    diagnosis: {
      required: requiredIf(function () {
        return this.branchLinkedWithNhso;
      }),
    },
    doctor: { required },
  },
  created() {
    this.init();
  },
  mounted() {},
  watch: {
    selectedListType(newValue) {
      localStorage.setItem("selectedListType", newValue);
    },
  },
  computed: {
    ...mapGetters({
      branchInfo: "moduleUser/getBranchInfo",
      getDoctorList: "moduleUser/getDoctorList",
      userInfo: "moduleUser/getUserInfo",
      isDoctor: "moduleUser/isDoctor",

      getToothBy: "moduleMaster/getToothBy",
      getToothSurfaceBy: "moduleMaster/getToothSurfaceBy",
      getTreatBy: "moduleAppointment/getTreatBy",
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",
      getGroupedTreatListPlan: "moduleAppointment/getGroupedTreatListPlan",
      getGroupedTreatDiagListPlan:
        "moduleAppointment/getGroupedTreatDiagListPlan",
      getGroupedTreatListNhso: "moduleAppointment/getGroupedTreatListNhso",
      getGroupedTreatDiagListNhso:
        "moduleAppointment/getGroupedTreatDiagListNhso",
      getGroupedTreatList: "moduleAppointment/getGroupedTreatList",
      getGroupedTreatDiagList: "moduleAppointment/getGroupedTreatDiagList",
      viewFile: "moduleContent/viewFile",
      branchLinkedWithNhso: "moduleUser/getLinkedWithNhso",
    }),
    teethValue() {
      if (!this.canEdit && !this.canCreate) return [];
      return this.teethSelected?.map(i => i.name).join(", ");
    },
    today() {
      return moment().locale("th").format("D MMMM YYYY");
    },
    ptFullname() {
      return (
        (this.patient?.titleNameTh ? this.patient?.titleNameTh + " " : "") +
        (this.patient?.firstNameTh ? this.patient?.firstNameTh + " " : "") +
        (this.patient?.lastNameTh ? this.patient?.lastNameTh : "")
      );
    },
    ptAddress() {
      let { address, subDistrict, district, province, country, postcode } =
        this.patient;
      let addressArr = [
        address,
        subDistrict,
        district,
        province,
        country,
        postcode,
      ];
      return addressArr.filter(i => i).join(" ");
    },
    canCreate() {
      return !this.planSelected.id;
    },
    canEdit() {
      return Boolean(this.planSelected.id);
    },
    printingField() {
      return this.tableFeilds.filter(field => field.printing);
    },
    totalPrice() {
      return this.treatmentPlan.reduce((accum, item) => {
        // Assuming expenses is the field you want to total up
        return accum + parseFloat(item?.price);
      }, 0);
    },
    highlightItems() {
      return this.treatmentPlan?.map(i => i?.toothNo);
    },
    logoUrl() {
      if (this.branchInfo?.clinic?.logo) {
        return this.viewFile(this.branchInfo.clinic.logo);
      }
      return "";
    },
    hasPlanChanged() {
      return (
        JSON.stringify(this.treatmentPlan) !==
        JSON.stringify(this.originalTreatmentPlan)
      );
    },
    currentTreatmentList() {
      const listMap = {
        Plan: "getGroupedTreatListPlan",
        NHSO: "getGroupedTreatListNhso",
        General: "getGroupedTreatList",
      };
      return this[listMap[this.selectedListType]];
    },
    currentDiagnosisList() {
      const listMap = {
        Plan: "getGroupedTreatDiagListPlan",
        NHSO: "getGroupedTreatDiagListNhso",
        General: "getGroupedTreatDiagList",
      };
      return this[listMap[this.selectedListType]];
    },
  },
  methods: {
    ...mapActions({
      createTreatmentPlan: "moduleTreatmentPlan/createTreatmentPlan",
      fetchTreatmentPlans: "moduleTreatmentPlan/fetchTreatmentPlans",
      updateTreatmentPlan: "moduleTreatmentPlan/updateTreatmentPlan",
    }),
    init() {},
    async show(patient) {
      this.patient = patient;
      this.patientId = patient.id;
      this.clinic = this.branchInfo.clinic;

      //Load Existing treatment plant
      try {
        this.isLoading = true;
        this.$refs["TreatmentPlan"].show();

        this.planList = await this.fetchTreatmentPlans({
          patientId: this.patientId,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        });
        this.planSelected =
          this.planList.filter(i => i.status === "Active")[0] || {};
        this.treatmentPlan = this.planSelected?.treatmentPlanItems || [];

        // sort by order
        if (this.treatmentPlan.some(plan => "order" in plan)) {
          this.treatmentPlan.sort((a, b) => {
            if ("order" in a && "order" in b) {
              return a.order - b.order;
            }
            return 0;
          });
        }
        this.originalTreatmentPlan = JSON.parse(
          JSON.stringify(this.treatmentPlan)
        );
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async handleCreateTreatmentPlan() {
      let isValid = await this.validateTreatmentPlan();
      if (!isValid) return;
      try {
        this.isLoading = true;
        let data = {
          patientId: this.patientId,
          nameTh: this.nameTh,
          status: "Active",
          treatmentPlanItems: this.treatmentPlan.map((item, index) => {
            return { ...item, status: "Planning", order: index + 1 };
          }),
        };
        let res;
        if (this.planSelected?.id) {
          let { id } = this.planSelected;
          res = await this.updateTreatmentPlan({
            data: { id, ...data },
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          });
        } else {
          res = await this.createTreatmentPlan({
            data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          });
        }
        if (res) {
          this.treatmentPlan = res.treatmentPlanItems;
          eventBus.$emit("alertToast", {
            message: "บันทึกแผนการรักษาสำเร็จ",
            variant: "success",
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
        await this.$nextTick();
        this.$refs["TreatmentPlan"].hide();
      }
    },
    handleHoveringTooth(e) {
      this.hoveringTooth = e;
    },
    onShow() {
      if (!this.canEdit && !this.canCreate) return;
      this.$v.$reset();
      this.popoverShow = true;
    },
    onClose() {
      this.defaultValuePopover();
      this.popoverShow = false;
    },
    onAdd() {
      this.$v.treatment.$touch();
      this.$v.diagnosis.$touch();
      if (this.$v.$anyError) return;
      this.price = this.price || 0;

      let items = this.formatItems();
      this.treatmentPlan.push(...items);
      this.onClose();
    },
    close() {},
    formatItems() {
      let items = [];
      this.teethSelected.forEach(i => {
        items.push({
          toothNo: i.id,
          toothSurfaces: this.toothSurfaces.map(s => s.id),
          diagnosis: this.diagnosis.id,
          treatment: this.treatment.id,
          price: this.price,
          detail: this.detail,
          status: "Planning",
          _showDetails: Boolean(this.detail),
        });
      });
      return items;
    },

    deleteRow(index) {
      this.treatmentPlan = this.treatmentPlan.filter((item, i) => i !== index);
    },
    favDxToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatDiagFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatDiagFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    favTxToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    openTreatDiagModal() {
      this.popoverShow = false;
      this.$refs.txDxPlanMoal.show(this.treatDiagList);
    },
    openTreatModal() {
      this.popoverShow = false;
      this.$refs.treatListModal.show(this.treatList);
    },
    defaultValuePopover() {
      this.teethSelected = [];
      this.toothSurfaces = [];
      this.diagnosis = null;
      this.treatment = null;
      this.price = 0;
      this.detail = "";
    },
    defaultValue() {
      this.defaultValuePopover();
      this.treatmentPlan = [];
      this.patientId = null;
      this.planList = [];
      this.planSelected = {};
      this.clinic = {};
      this.patient = {};
      this.patientId = null;
      this.doctor = null;
    },

    // Utils
    customDxLabel(libs) {
      return `${libs.code ? "[" + libs.code + "]-" : ""}${libs.nameEn}`;
    },
    customTxDrugsLabel(option) {
      if (option.nameTh == option.nameEn) {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}`;
      } else {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}${
          option.nameEn ? " / " + option.nameEn : ""
        }`;
      }
    },
    getToothSurfacesPopover(toothSurfaces) {
      this.toothSurfaces = [];
      if (toothSurfaces.length > 0) {
        toothSurfaces.forEach(res => {
          this.toothSurfaces.push(this.getToothSurfaceBy(res, "id"));
        });
      }
    },
    async focusPriceInput() {
      await this.$nextTick();
      this.price = this.treatment.price;
      this.$refs.treatmentPriceInputPopover.focus();
    },
    async validateTreatmentPlan() {
      if (this.treatmentPlan?.length < 1) {
        await this.$refs.Dialog.showAlertInfo(
          "กรุณาบันทึกเพิ่มแผนการรักษา",
          "ต้องมีอย่างน้อย 1 รายการ",
          "warning"
        );
        return false;
      }
      let { isConfirmed } = await this.$refs.Dialog.showAlertConfirm(
        this.planSelected?.id ? "แก้ไขแผนการรักษา ?" : "บันทึกแผนการรักษา ?"
      );
      return isConfirmed;
    },
    async generatePdf() {
      this.$v.doctor.$touch();
      if (this.$v.doctor.$error) return;
      this.printingPlan = this.treatmentPlan;
      this.isPdfCreating = true;

      await this.$nextTick();
      await this.$refs.pdfExport.generatePdf({
        header: this.$refs.header,
        body: this.$refs.body,
        footer: this.$refs.footer,
      });
      this.isPdfCreating = false;
      this.printingPlan = [];
      this.$v.$reset();
    },
    async keepPopoverShown() {
      this.$nextTick();
      this.popoverShow = true;
    },

    onDragEnd(evt) {
      this.$nextTick(() => {
        // Reassign order based on new positions
        this.treatmentPlan = this.treatmentPlan.map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      });
    },

    removeRow(index) {
      this.treatmentPlan.splice(index, 1);
      this.updateOrder();
    },

    updateOrder() {
      this.treatmentPlan = this.treatmentPlan.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
    },
    toggleDetails(item) {
      this.$set(item, "_showDetails", !item?._showDetails);
    },
    formatToothSurfaces(surfaces) {
      return (
        surfaces?.map(i => this.getToothSurfaceBy(i, "id").name).join(", ") ||
        ""
      );
    },
    formatDiagnosis(diagnosisId) {
      const obj = this.getTreatDiagBy(diagnosisId, "id");
      return obj?.code ? `[${obj.code}]-${obj.nameEn}` : obj?.nameEn || "";
    },
    formatTreatment(treatmentId) {
      const obj = this.getTreatBy(treatmentId, "id");
      return obj?.code ? `[${obj.code}]-${obj.nameTh}` : obj?.nameTh || "";
    },
    handleEnterKeyPress(event) {
      console.log("test");
      if (this.popoverShow) {
        this.onAdd();
      }
    },
  },
};
</script>

<style>
.treatment-plan-modal {
  overflow: visible !important;
}

.treatment-plan-modal .modal-content {
  overflow: visible !important;
}
.treatment-plan-popover {
  min-width: 320px !important;
  font-size: 0.8rem;
}
.drag-handle {
  cursor: grab;
  padding-right: 5px;
  color: #888;
}
.drag-handle:active {
  cursor: grabbing;
}

.expand-dropdown > .multiselect__content-wrapper {
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  min-width: 100%;
  width: 80vw; /* Default to full width on smaller screens */
}
@media (min-width: 480px) {
  .expand-dropdown > .multiselect__content-wrapper {
    width: calc(100vw - 50%);
  }
}
/* Small screens */
@media (min-width: 640px) {
  .expand-dropdown > .multiselect__content-wrapper {
    width: calc(100vw - 180%);
  }
}

/* Medium screens */
@media (min-width: 768px) {
  .expand-dropdown > .multiselect__content-wrapper {
    width: calc(100vw - 200%);
  }
}

/* Large screens */
@media (min-width: 1024px) {
  .expand-dropdown > .multiselect__content-wrapper {
    width: calc(100vw - 200%);
  }
}

/* Extra large screens */
@media (min-width: 1280px) {
  .expand-dropdown > .multiselect__content-wrapper {
    width: calc(100vw - 250%);
  }
}
</style>