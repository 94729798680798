<template>
  <b-modal
    modal-class="component-modal-customer"
    ref="CustomerModal"
    size="xl fluid"
    title="ข้อมูลคนไข้"
    :hide-footer="!isManagerOrCounter || readOnly"
    no-close-on-backdrop
    @hidden="close"
  >
    <b-form
      ref="newPatientForm"
      id="newPatientForm"
      autocomplete="off"
      @submit.prevent="debouncedSavePatient"
    >
      <b-row>
        <b-col cols="12" sm="3" lg="2" class="divider-right">
          <b-row>
            <b-col cols="6" sm="12">
              <b-form-group class="mb-1" label="DN:" label-class="pb-0">
                <b-form-input
                  type="text"
                  v-model="customer.dn"
                  placeholder="กำหนดอัตโนมัติ"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="DN(เดิม):"
                label-class="pb-0"
                :disabled="!isManagerOrCounter || readOnly"
              >
                <b-form-input type="text" trim v-model="customer.existDn" />
              </b-form-group>
            </b-col>
            <b-col cols="6" sm="12">
              <b-form-group
                label="รูปถ่าย :"
                label-class="pb-0"
                description
                :disabled="!isManagerOrCounter"
              >
                <upload-avatar-form
                  ref="UploadAvatar"
                  class="d-inline-block"
                  square
                  border
                  size="119px"
                  :uploadMaxSize="180"
                  :default-id="defaultContentId"
                  @callback="uploadAvatarCallback"
                ></upload-avatar-form>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="d-sm-none mt-0" />
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="12" lg="6" class="divider-right">
              <b-form-group
                label="ประวัติส่วนตัว:"
                description
                :disabled="!isManagerOrCounter || readOnly"
              >
                <b-form-row>
                  <SmartCard
                    class="col"
                    ref="smartcard"
                    @getReaderData="getReaderData"
                    @showDialogToast="showDialogToast"
                  >
                  </SmartCard>
                  <SmartCardMOI
                    class="col"
                    ref="smartcardMoi"
                    @getReaderData="getReaderData"
                    @showDialogToast="showDialogToast"
                  >
                  </SmartCardMOI>
                  <select
                    class="col ml-2 form-control"
                    v-model="customer.personalType"
                  >
                    <option
                      v-for="item in personalTypeList"
                      :key="item.val"
                      :value="item.val"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                  <span>
                    <b-form-input
                      id="personalId"
                      class="col ml-2"
                      :class="{ 'is-invalid': $v.customer.personalId.$error }"
                      v-model="$v.customer.personalId.$model"
                      trim
                      type="text"
                      placeholder="เลขบัตร"
                      @change="debouncedCheckDuplicates"
                    ></b-form-input>
                    <div
                      v-if="$v.customer.personalId.$error"
                      class="description col text-danger"
                    >
                      เลข 13 หลักไม่ถูกต้อง
                    </div>
                  </span>

                  <b-popover
                    id="matched-pid-popover"
                    target="personalId"
                    triggers="manual"
                    variant="danger"
                  >
                    <template #title>
                      <b-button
                        @click="closePopover"
                        class="close"
                        aria-label="Close"
                      >
                        <span
                          class="float-right ml-2"
                          style="line-height: 0.8"
                          aria-hidden="true"
                          >&times;</span
                        > </b-button
                      >เลขประจำตัวซ้ำ กับคนไข้เดิม
                    </template>
                    <b-button
                      block
                      variant="danger"
                      @click="openSearchCustomerModal(customer.personalId)"
                      >เลือกคนไข้เดิม</b-button
                    >
                  </b-popover>
                </b-form-row>
                <b-form-row class="mt-3">
                  <vue-typeahead-bootstrap
                    class="col-3 pl-0 pr-0"
                    v-model="customer.titleNameTh"
                    :data="getTitleNameList"
                    :serializer="s => s.label"
                    placeholder="คำนำหน้า"
                    ref="titleNameTh"
                    :minMatchingChars="0"
                    :showOnFocus="true"
                  />
                  <b-form-input
                    id="firstname-input"
                    autofocus
                    class="col ml-2 required"
                    v-model="$v.customer.firstNameTh.$model"
                    trim
                    lazy
                    type="text"
                    placeholder="ชื่อ"
                    :class="{ 'is-invalid': $v.customer.firstNameTh.$error }"
                    @change="debouncedCheckDuplicates"
                  ></b-form-input>
                </b-form-row>

                <b-form-row class="mt-2">
                  <b-form-input
                    id="lastname-input"
                    class="col required"
                    v-model="$v.customer.lastNameTh.$model"
                    trim
                    lazy
                    type="text"
                    placeholder="สกุล"
                    :class="{ 'is-invalid': $v.customer.lastNameTh.$error }"
                    @change="debouncedCheckDuplicates"
                  ></b-form-input>
                  <b-popover
                    id="matched-name-popover"
                    target="lastname-input"
                    triggers="manual"
                    placement="bottom"
                    variant="warning"
                  >
                    <template #title>
                      <b-button
                        @click="closePopover"
                        class="close"
                        aria-label="Close"
                      >
                        <span
                          class="float-right ml-2"
                          style="line-height: 0.8"
                          aria-hidden="true"
                          >&times;</span
                        > </b-button
                      >ชื่อ-สกุลซ้ำ กับคนไข้เดิม
                    </template>
                    <b-button
                      block
                      variant="warning"
                      @click="
                        openSearchCustomerModal(
                          customer.firstNameTh + ' ' + customer.lastNameTh
                        )
                      "
                      >เลือกคนไข้เดิม</b-button
                    >
                  </b-popover>
                </b-form-row>
                <b-form-row class="mt-4">
                  <vue-typeahead-bootstrap
                    class="col-3 pl-0 pr-0"
                    v-model="customer.titleNameEn"
                    :data="getTitleNameEngList"
                    :serializer="s => s.label"
                    placeholder="Title"
                    ref="titleNameEng"
                    :minMatchingChars="0"
                    :showOnFocus="true"
                  />
                  <b-form-input
                    class="col ml-2"
                    v-model="customer.firstNameEn"
                    type="text"
                    placeholder="ชื่อ(Eng)"
                  ></b-form-input>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-form-input
                    class="col"
                    v-model="customer.lastNameEn"
                    type="text"
                    placeholder="สกุล(Eng)"
                  ></b-form-input>
                </b-form-row>
                <b-form-row class="mt-4">
                  <b-form-input
                    class="col"
                    v-model="customer.nickName"
                    type="text"
                    placeholder="ชื่อเล่น"
                  ></b-form-input>
                  <vue-typeahead-bootstrap
                    class="col pl-0 pr-0 ml-2"
                    v-model="customer.gender"
                    :data="getGenderList"
                    :serializer="s => s.label"
                    placeholder="เพศ"
                    ref="gender"
                    :min-matching-chars="0"
                    :showOnFocus="true"
                  />
                </b-form-row>
                <b-form-row class="mt-2">
                  <datetime
                    class="col p-0"
                    input-class="form-control"
                    v-model="rawDob"
                    format="cccc dd LLLL yyyy"
                    :max-datetime="now"
                    value-zone="UTC+7"
                    auto
                    placeholder="วันเกิด"
                    :flow="['year', 'month', 'date']"
                  >
                  </datetime>
                </b-form-row>
                <b-form-row class="mt-4">
                  <b-form-input
                    class="col"
                    v-model="customer.congenitalDisease"
                    type="text"
                    placeholder="โรคประจำตัว"
                  ></b-form-input>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-form-input
                    class="col required"
                    v-model="$v.customer.allergic.$model"
                    type="text"
                    placeholder="อาการแพ้"
                    :class="{ 'is-invalid': $v.customer.allergic.$error }"
                  ></b-form-input>
                </b-form-row>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="isManagerOrCounter ? 'รายละเอียดติดต่อ :' : ''"
                description
                :disabled="!isManagerOrCounter || readOnly"
              >
                <div v-if="isManagerOrCounter">
                  <b-form-row>
                    <b-form-input
                      class="col required"
                      type="text"
                      placeholder="เบอร์โทร:มือถือ"
                      :class="{ 'is-invalid': $v.customer.mobile.$error }"
                      @input="applyMobileNumber"
                      :value="dashedMobile"
                    ></b-form-input>
                    <b-form-input
                      class="col ml-2"
                      type="text"
                      placeholder="เบอร์โทร:บ้าน"
                      @input="applyTelNumber"
                      :value="dashedTel"
                    ></b-form-input>
                  </b-form-row>
                  <b-form-row class="mt-2">
                    <b-form-input
                      class="col"
                      v-model="customer.lineId"
                      type="text"
                      placeholder="ไลน์ไอดี"
                    ></b-form-input>
                    <b-form-input
                      class="col ml-2"
                      v-model="customer.email"
                      type="email"
                      placeholder="อีเมล์"
                    ></b-form-input>
                  </b-form-row>
                  <b-form-row class="mt-3">
                    <b-form-textarea
                      v-model="customer.address"
                      placeholder="ที่อยู่"
                      rows="2"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-row>
                  <b-form-row class="mt-2">
                    <b-form-input
                      class="col"
                      v-model="customer.subDistrict"
                      type="text"
                      placeholder="ตำบล/แขวง"
                    ></b-form-input>
                    <b-form-input
                      class="col ml-2"
                      v-model="customer.district"
                      type="text"
                      placeholder="อำเภอ/เขต"
                    ></b-form-input>
                  </b-form-row>
                  <b-form-row class="mt-2">
                    <b-form-input
                      class="col"
                      v-model="customer.province"
                      type="text"
                      placeholder="จังหวัด"
                    ></b-form-input>
                    <b-form-input
                      class="col ml-2"
                      v-model="customer.country"
                      type="text"
                      placeholder="ประเทศ"
                    ></b-form-input>
                  </b-form-row>
                  <b-form-row class="mt-2">
                    <b-form-input
                      class="col"
                      v-model="customer.postcode"
                      type="text"
                      placeholder="รหัสไปรษณีย์"
                    ></b-form-input>
                  </b-form-row>
                </div>
                <b-form-row class="mt-3">
                  <select class="form-control col" v-model="customer.doctorId">
                    <option value="null" selected disabled>คุณหมอประจำ</option>
                    <option
                      v-for="val in doctorList"
                      :key="val.uid"
                      :value="val.uid"
                    >
                      {{ val.fullName }}
                    </option>
                  </select>
                </b-form-row>

                <b-form-row class="mt-3">
                  <multiselect
                    class="col p-0"
                    v-model="reqExtDocItems"
                    :options="reqExtDocList"
                    :multiple="true"
                    placeholder="เอกสารการรักษา"
                    track-by="label"
                    label="label"
                    :limit="2"
                  ></multiselect>
                </b-form-row>
                <b-form-row class="mt-3">
                  <b-form-textarea
                    v-model="customer.note"
                    placeholder="บันทึกเพิ่มเติม"
                    rows="2"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-row>
              </b-form-group>
              <b-form-checkbox
                v-if="branchLinkedWithKtbPaotang"
                v-model="customer.linkedWithKtbPaotang"
                switch
                style="font-weight: 400"
              >
                เชื่อมกับ "เป๋าตัง"
                <img
                  class="mr-1"
                  style="width: auto; height: 20px"
                  :src="PaotangLogo"
                />
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <template v-slot:modal-footer>
      <!-- <b-button form="newPatientForm" @click="resetButton" variant="danger"
        >รีเซ็ต</b-button
      >-->
      <b-button type="submit" form="newPatientForm" variant="primary"
        >{{ saveButtonTitle }}
      </b-button>
    </template>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import SmartCard from "@/components/SmartCard";
import SmartCardMOI from "@/components/SmartCardMOI";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import UploadAvatarForm from "@/components/form/UploadAvatar";
import PaotangLogo from "../../assets/img/Paotang-small.png";
import debounce from "@/utils/debounce";

import { eventBus } from "@/main";

import {
  required,
  requiredIf,
  numeric,
  minLength,
  maxLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

const digits = number =>
  helpers.withParams(
    { type: "digits", value: number },
    value => !helpers.req(value) || helpers.len(value) == number
  );

const validateThaiID = id => {
  // ตรวจสอบว่าเลขที่กรอกเข้ามามีความยาว 13 หลัก
  if (!/^\d{13}$/.test(id)) return false;

  // แปลง string ของ id ให้เป็น array ของตัวเลข
  let digits = id.split("").map(Number);

  // คำนวณผลรวมจากตัวเลข 12 ตัวแรก โดยใช้สูตร 13 - (ลำดับของตัวเลข)
  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += digits[i] * (13 - i);
  }

  // คำนวณ check digit โดยเอา 11 ลบด้วยผลลัพธ์ของ sum mod 11 แล้ว mod 10 อีกครั้ง
  let checkDigit = (11 - (sum % 11)) % 10;

  // ตรวจสอบว่า check digit ตรงกับเลขตัวสุดท้ายของบัตรประชาชนหรือไม่
  return checkDigit === digits[12];
};

export default {
  name: "CustomerModal",
  components: {
    SmartCard,
    SmartCardMOI,
    Loading,
    Dialog,
    UploadAvatarForm,
  },
  data() {
    return {
      isLoading: false,
      customer: {
        id: null,
        personalType: 1,
        personalId: null,
        dn: null,
        titleNameTh: null,
        firstNameTh: null,
        lastNameTh: null,
        titleNameEn: null,
        firstNameEn: null,
        lastNameEn: null,
        nickName: null,
        gender: null,
        mobile: null,
        email: null,
        lineId: null,
        dob: null,
        tel: null,
        congenitalDisease: null,
        allergic: null,
        reqDocItems: null,
        note: null,
        contentId: null,
        doctorId: null,
        address: null,
        subDistrict: null,
        district: null,
        province: null,
        postcode: null,
        country: null,
        existDn: null,
        linkedWithKtbPaotang: false,
      },
      rawDob: null,
      reqExtDocItems: [],
      appointment: null,
      defaultContentId: null,

      isAutoAssignDn: true,
      readOnly: false,
      openFrom: null,

      dashedMobile: "",
      dashedTel: "",
      PaotangLogo,
      debouncedSavePatient: null,
      debouncedCheckDuplicates: null,
    };
  },
  validations() {
    if (
      (this.customer.personalId && this.customer.personalType === 1) ||
      (this.customer.linkedWithKtbPaotang && this.branchLinkedWithKtbPaotang)
    ) {
      return {
        customer: {
          personalId: { required, numeric, validateThaiID },
          firstNameTh: { required },
          lastNameTh: { required },
          allergic: { required },
          mobile: {
            required,
            // numeric,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
        },
      };
    } else {
      return {
        customer: {
          personalId: {},
          firstNameTh: { required },
          lastNameTh: { required },
          allergic: { required },
          mobile: {
            required,
            // numeric,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
        },
      };
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.debouncedSavePatient = debounce(this.savePatient);
    this.debouncedCheckDuplicates = debounce(this.checkDupicates);
  },
  computed: {
    ...mapGetters({
      getUserInfo: "moduleUser/getUserInfo",
      getGenderList: "moduleMaster/getGenderList",
      getTitleNameList: "moduleMaster/getTitleNameList",
      getTitleNameEngList: "moduleMaster/getTitleNameEngList",
      personalTypeList: "moduleMaster/getPersonalTypeList",
      reqExtDocList: "moduleMaster/getReqExtDocList",
      reqExtDocBy: "moduleMaster/getReqExtDocBy",
      doctorList: "moduleUser/getDoctorList",
      appointmentBy: "moduleAppointment/getQueueAppointmentBy",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getBranchInfo: "moduleUser/getBranchInfo",
      branchLinkedWithKtbPaotang: "moduleUser/getLinkedWithKtbPaotang",
    }),
    now() {
      return moment().format();
    },
    isManagerOrCounter() {
      return this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"]);
    },
    saveButtonTitle() {
      if (this.customer.id) return "แก้ไข";
      return "สร้างคนไข้ใหม่";
    },
    formattedDob() {
      if (this.rawDob) {
        const d = moment(this.rawDob).locale("th").format("YYYY-MM-DD");
        return d === "Invalid date" ? null : d;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      createPatient: "modulePatient/createPatient",
      updatePatient: "modulePatient/updatePatient",
      updateStateQueueAppointmentPatient:
        "moduleAppointment/updateStateQueueAppointmentPatient",
      checkNamePatient: "modulePatient/checkNamePatient",
      checkDuplicate: "modulePatient/checkDuplicate",
    }),
    init() {},
    show(customer, appointment = null, readOnly = false, openFrom = null) {
      this.setDefultCustomer();
      this.appointment = appointment; //ห้ามแก้เป็น {...appointment} จะทำให้เกิด bug ตอนเปลี่ยนจาก unNameApt เป็น apt Why???
      this.readOnly = readOnly;
      this.openFrom = openFrom;

      this.$refs["CustomerModal"].show();
      if (customer) {
        this.customer = Object.assign({}, customer);
        this.rawDob = this.customer.dob;
        this.applyMobileNumber(customer.mobile);
        this.applyTelNumber(customer.tel);
        this.reqExtDocItems = [];
        this.customer.reqDocItems?.forEach(item => {
          const reqExtDoc = this.reqExtDocBy(item, "val");
          this.reqExtDocItems.push(reqExtDoc);
        });
        this.defaultContentId = customer.contentId ? customer.contentId : null;
      } else {
        // this.setDefultCustomer();
      }
    },
    close() {
      this.setDefultCustomer();
      this.$v.$reset();
    },
    getReaderData(readerData) {
      if (readerData) {
        this.customer.personalType = 1;
        this.customer.personalId = readerData.Citizenid;
        this.customer.titleNameTh = readerData.Th_Prefix;
        this.customer.firstNameTh = readerData.Th_Firstname;
        this.customer.lastNameTh = readerData.Th_Lastname;
        this.customer.titleNameEn = readerData.En_Prefix;
        this.customer.firstNameEn = readerData.En_Firstname;
        this.customer.lastNameEn = readerData.En_Lastname;
        this.customer.gender = readerData.gender;
        this.rawDob = readerData.Birthday;
        this.customer.province = readerData.addrProvince;
        this.customer.district = readerData.addrAmphur;
        this.customer.subDistrict = readerData.addrTambol;
        this.customer.address = readerData.addrHouseNo;
        this.photoRawToFile(readerData.PhotoRaw);
      }
    },
    photoRawToFile(data) {
      var dataURI = "data:image/jpeg;base64," + data;
      this.$refs.UploadAvatar.setImage(
        dataURI,
        `cid-${this.customer.personalId}.jpg`
      );
    },
    uploadAvatarCallback(state, contentId) {
      switch (state) {
        case "uploading":
          this.isLoading = true;
          break;
        case "callback":
          this.isLoading = false;
          this.checkPatient();
          break;
        case "delete":
          this.customer.contentId = contentId;
          break;
        case "reset":
          this.customer.contentId = contentId;
          break;
        case "success":
          this.isLoading = false;
          this.customer.contentId = contentId;
          this.checkPatient();
          break;
        case "fail":
          this.isLoading = false;
          this.showDialogToast("danger", `ไม่สามารถอัพโหลดรูปโปรไฟล์ได้`);
          this.checkPatient();
          break;
        default:
      }
    },
    resetForm() {
      this.$refs.UploadAvatar.reset("");
      this.setDefultCustomer();
      this.$v.$reset();
    },
    savePatient() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      this.$refs.UploadAvatar.submit();
    },
    async checkPatient() {
      let isDismissed;
      if (
        this.getBranchInfo.linkedWithNhso &&
        this.customer.personalType === 1 &&
        this.customer.personalId?.length !== 13
      ) {
        await this.$refs.Dialog.showAlertConfirm(
          "คุณไม่ได้กรอกเลขบัตรประชาชน",
          "ต้องการสร้างคนไข้ใหม่ โดยไม่มีเลขบัตรประชาชน",
          "warning",
          "สร้างคนไข้ใหม่โดยไม่มีเลขบัตร",
          "กลับไปเพิ่มเลขบัตร"
        ).then(res => {
          isDismissed = res.isDismissed;
        });
      }
      if (isDismissed) return;

      this.isLoading = true;
      let matched = await this.checkDupicatePatients();
      this.isLoading = false;

      if (matched && matched.duplicateBy === "PID") {
        this.$refs.Dialog.showAlertInfo(
          "เลขประจำตัวซ้ำ กับคนไข้เดิม",
          "เลือกจากคนไข้เดิม",
          "warning"
        ).then(() => {
          this.openSearchCustomerModal(this.customer.personalId);
          this.isLoading = false;
          return;
        });
      } else if (
        matched &&
        matched.duplicateBy === "NAME" &&
        !this.customer.id
      ) {
        this.$refs.Dialog.showAlertConfirm(
          "ชื่อ และนามสกุลซ้ำกับคนไข้ที่เดิม",
          "ต้องการสร้างคนไข้ซ้ำ?",
          "warning",
          "สร้างคนไข้ชื่อซ้ำ",
          "เลือกจากคนไข้เดิม",
          true
        ).then(res => {
          if (res.isDismissed) {
            this.openSearchCustomerModal(
              this.customer.firstNameTh + " " + this.customer.lastNameTh
            );
            this.isLoading = false;
            return;
          } else {
            this.saveCustomer();
          }
        });
      } else {
        this.saveCustomer();
      }
    },
    saveCustomer() {
      this.isLoading = true;

      if (!this.customer.id) {
        this.createPatient({
          customer: {
            ...this.customer,
            dob: this.formattedDob,
          },
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
          .then(res => {
            this.isLoading = false;
            if (res.status == 200) {
              this.customer.dn = res.data.dn;
              this.customer.id = res.data.id;

              if (this.openFrom === "UnnamedPtApt") {
                eventBus.$emit("applyPt", this.customer);
                eventBus.$emit("alertToast", {
                  message: "สร้างคนไข้สำเร็จ",
                  variant: "success",
                });
              } else if (this.openFrom === "QManage") {
                let event = {
                  id: parseInt(this.appointment.id),
                  startDt: this.appointment.start,
                  endDt: this.appointment.end,
                  roomId: parseInt(this.appointment.extendedProps.roomId),
                  aptTreatItems: this.appointment.extendedProps.treatmentList,
                  rmdId: this.appointment.extendedProps.rmdId,
                  doctorUid: this.appointment.extendedProps.doctor,
                  note: this.appointment.extendedProps.note,
                  patientId: this.customer.id,
                };
                this.$store.dispatch(
                  "moduleAppointment/updateQueueAppointment",
                  {
                    event,
                    clinicUrl: this.$route.params.clinicUrl,
                    branchUrl: this.$route.params.branchUrl,
                  }
                );
              } else {
                this.$emit(
                  "openProfileCustomerModal",
                  Object.assign({}, this.appointment),
                  Object.assign({}, this.customer)
                );
              }

              this.$refs["CustomerModal"].hide();
            }
          })
          .catch(res => {
            this.isLoading = false;
            if (res.response?.data?.code === "PAT0001") {
              this.$root.$emit("bv::show::tooltip", "personalId");
              this.$refs.Dialog.showAlertInfo(
                "เลขบัตรประชาชน/Passport ซ้ำ",
                "ไม่สามารถสร้างประวัติคนไข้ใหม่ได้",
                "warning"
              ).then(() => {
                setTimeout(
                  () => this.$root.$emit("bv::hide::tooltip", "personalId"),
                  8000
                );
              });
            }
          });
      } else {
        this.$refs.Dialog.showAlertConfirm(
          "แก้ไขข้อมูลคนไข้ ?",
          "คุณต้องการแก้ไข รายละเอียดข้อมูลคนไข้"
        ).then(res => {
          if (res.isConfirmed) {
            this.updatePatient({
              customer: {
                ...this.customer,
                dob: this.formattedDob,
              },
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
              .then(res => {
                if (res.status == "200") {
                  this.$refs["CustomerModal"].hide();
                  this.customer = res.data;
                  let appointment = Object.assign({}, this.appointment);
                  let patient = Object.assign({}, this.customer);
                  if (appointment && patient) {
                    this.updateStateQueueAppointmentPatient({
                      id: appointment.id,
                      patient: patient,
                    });
                  }
                  eventBus.$emit("updatePatientInfo");

                  // this.$emit("openProfileCustomerModal", appointment, patient);
                }
                this.isLoading = false;
              })
              .catch(err => {
                console.error(err);
                this.isLoading = false;
              });
          } else {
            this.isLoading = false;
          }
        });
      }
    },
    async checkDupicates() {
      let matched = await this.checkDupicatePatients();
      if (matched && matched.duplicateBy === "PID") {
        this.$root.$emit("bv::show::popover", "matched-pid-popover");
      } else if (matched && matched.duplicateBy === "NAME") {
        this.$root.$emit("bv::show::popover", "matched-name-popover");
      }
    },
    checkDupicatePatients() {
      let { personalId, firstNameTh, lastNameTh } = this.customer;
      if (!personalId && !firstNameTh && !lastNameTh) return;
      if (this.customer.personalId === "") {
        this.customer.personalId = null;
      }
      if (this.customer.firstNameTh === "") {
        this.customer.firstNameTh = null;
      }
      if (this.customer.lastNameTh === "") {
        this.customer.lastNameTh = null;
      }
      return this.checkDuplicate({
        customer: this.customer,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      }).then(res => {
        let dupData = res.data;
        if (dupData?.result?.length > 0) {
          return res.data;
        } else {
          this.closePopover();
          return false;
        }
      });
    },
    closePopover() {
      this.$root.$emit("bv::hide::popover");
    },
    customLabel(option) {
      return `${option.label}`;
    },
    selectReqExtDocItems() {
      let reqExtDocItems = [];
      this.reqExtDocItems.forEach(element => {
        reqExtDocItems.push(element.value);
      });
      this.customer.reqDocItems = reqExtDocItems;
    },
    resetButton() {
      this.$refs.Dialog.showAlertConfirm("รีเซ็ตข้อมูล ?").then(result => {
        if (!result.value) {
          return;
        }
      });
      this.setDefultCustomer();
    },
    setDefultCustomer() {
      this.customer = Object.assign(
        {},
        {
          personalType: 1,
          personalId: null,
          dn: null,
          titleNameTh: null,
          firstNameTh: null,
          lastNameTh: null,
          titleNameEn: null,
          firstNameEn: null,
          lastNameEn: null,
          nickName: null,
          gender: null,
          mobile: null,
          email: null,
          lineId: null,
          dob: null,
          tel: null,
          congenitalDisease: null,
          allergic: null,
          reqDocItems: null,
          note: null,
          contentId: null,
          doctorId: null,
          address: null,
          subDistrict: null,
          district: null,
          province: null,
          postcode: null,
          country: null,
          existDn: null,
          linkedWithKtbPaotang: false,
        }
      );
      this.isLoading = false;
      this.reqExtDocItems = [];
      this.appointment = null;
      this.defaultContentId = null;
      this.dashedMobile = "";
      this.dashedTel = "";
      this.rawDob = null;
    },
    showDialogToast(variant, textDetail) {
      this.$refs.Dialog.showToast(variant, textDetail);
    },
    openSearchCustomerModal(val) {
      this.$refs["CustomerModal"].hide();
      this.$parent.openSearchCustomerModal(val);
    },
    applyMobileNumber(e) {
      if (!e) return;
      let num = e.replace(/[^0-9]+/g, "");
      this.$v.customer.mobile.$model = num;
      this.dashedMobile = num.replace(
        /(\d{1,3})(\d{1,3})?(\d{1,10})?/g,
        (txt, f, s, t) => {
          if (t) {
            return `${f}-${s}-${t}`;
          } else if (s) {
            return `${f}-${s}`;
          } else if (f) {
            return `${f}`;
          }
        }
      );
    },
    applyTelNumber(e) {
      if (!e) return;
      let num = e.replace(/[^0-9]+/g, "");
      this.customer.tel = num;
      let isBkk = num.slice(0, 2) == "02";
      if (isBkk) {
        this.dashedTel = num.replace(
          /(\d{1,2})(\d{1,3})?(\d{1,10})?/g,
          (txt, f, s, t) => {
            if (t) {
              return `${f}-${s}-${t}`;
            } else if (s) {
              return `${f}-${s}`;
            } else if (f) {
              return `${f}`;
            }
          }
        );
      } else {
        this.dashedTel = num.replace(
          /(\d{1,3})(\d{1,3})?(\d{1,10})?/g,
          (txt, f, s, t) => {
            if (t) {
              return `${f}-${s}-${t}`;
            } else if (s) {
              return `${f}-${s}`;
            } else if (f) {
              return `${f}`;
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.required {
  background-color: #ccecff6c;
}

.form-row {
  margin-left: 0;
  margin-right: 0;
}
</style>